/* Copyright 2022 Esri
 *
 * Licensed under the Apache License Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useRef, useEffect } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import esriConfig from "@arcgis/core/config";
import Basemap from "@arcgis/core/Basemap";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import { whenTrue } from "@arcgis/core/core/watchUtils";
import Graphic from "@arcgis/core/Graphic";
import pngMarker from "./marker.png";

const toggleViewFinder = (visible) => {
  const display = visible ? "block" : "none";
  document.getElementById("square").style.display = display;
  document.getElementById("axis-y-top").style.display = display;
  document.getElementById("axis-y-bottom").style.display = display;
  document.getElementById("axis-x-left").style.display = display;
  document.getElementById("axis-x-right").style.display = display;
};

export const THMap = ({
  id,
  className,
  initCenter,
  homeZoom,
  minZoom,
  maxZoom,
  scaleDenominator,
  selected,
  onSolve,
}) => {
  const _initCenter = useRef(initCenter);
  const _homeZoom = useRef(homeZoom);
  const _minZoom = useRef(minZoom);
  const _maxZoom = useRef(maxZoom);
  const _reportSolved = useRef(onSolve);
  const _selected = useRef(selected);
  const _scaleDenominator = useRef(scaleDenominator);
  const _view = useRef(null);

  const _performCrossHairTest = useRef(() => {
    const view = _view.current;
    const square = document.getElementById("square");

    const selectedPoint = new Point({
      x: _selected.current.x,
      y: _selected.current.y,
      spatialReference: { wkid: 4326 },
    });
    if (view.zoom < 7) {
    } else if (boxToExtent(view, square).contains(selectedPoint)) {
      if (!_selected.current.solved) {
        square.classList.add("blinking");
        setTimeout(
          () =>
            view
              .goTo(
                {
                  center: selectedPoint,
                  zoom: _selected.current.zoom_level,
                },
                {
                  animate: true,
                  duration: 2000,
                  easing: "ease-out",
                }
              )
              .then(() => {
                square.classList.remove("blinking");
                toggleViewFinder(false);

                view.graphics.add(
                  new Graphic({
                    geometry: selectedPoint,
                    symbol: {
                      type: "picture-marker",
                      url: pngMarker,
                      width: "24px",
                      height: "32px",
                      yoffset: "16px",
                    },
                  })
                );

                view.popup.dockEnabled = false;
                view.popup.open({
                  location: selectedPoint,
                  title: _selected.current.location_name,
                });
                _reportSolved.current(_selected.current.OBJECTID);
              }),
          1000
        );
      }
    }
  });

  const _updateCrosshairColor = useRef(() => {
    const view = _view.current;
    const square = document.getElementById("square");

    if (view.zoom < 7) {
      square.style.backgroundColor = "rgba(0,0,0,0.6)";
    } else {
      const selectedPoint = new Point({
        x: _selected.current.x,
        y: _selected.current.y,
        spatialReference: { wkid: 4326 },
      });


      const viewCenter = new Point({
        x: view.center.longitude,
        y: view.center.latitude,
        spatialReference: { wkid: 4326 },
      });

      const distance = viewCenter.distance(selectedPoint);
      const distanceRatio = distance / _scaleDenominator.current;
      const intensity = 1 - distanceRatio;

      // console.log("************************************")
      // console.log("distance", distance);
      // console.log("scale denominator", scaleDenominator)
      // console.log("distance ratio", distanceRatio);
      // console.log("intensity", intensity);

      const green = parseInt(255 * intensity);
      const red = parseInt(255 * (1 - intensity));

      square.style.backgroundColor = `rgba(${red + "," + green + ",0,0.6"}`;
    }
  });

  useEffect(() => {

    let vtlLayer = new VectorTileLayer({
        // URL to the style of vector tiles
        url: "https://www.arcgis.com/sharing/rest/content/items/59d75d6ed9364614b044aee6d402e4ca/resources/styles/root.json"
      });

    esriConfig.apiKey =
      "AAPKc281cec04c56424bb82093c8925ea337x_K4mBEA-vKPfea5-iSQuzoKoHc5eupD1JQwl-4R_a3AoGuNVdUfNdzbDEQn2jZ2";
    const view = new MapView({
      map: new Map({  layers: [vtlLayer] }),
      container: "map",
      center: _initCenter.current,
      zoom: _homeZoom.current,
      constraints: {
        minZoom: _minZoom.current,
        maxZoom: _maxZoom.current,
      },
    });

    view.popup.visibleElements = { closeButton: false };
    view.popup.dockOptions.buttonEnabled = false;
    view.popup.actions.removeAll();
    view.popup.alignment = "top-center";

    const square = document.createElement("div");
    square.setAttribute("id", "square");
    view.ui.add(square, "manual");

    const axisYTop = document.createElement("div");
    axisYTop.setAttribute("id", "axis-y-top");
    view.ui.add(axisYTop, "manual");

    const axisYBottom = document.createElement("div");
    axisYBottom.setAttribute("id", "axis-y-bottom");
    view.ui.add(axisYBottom, "manual");

    const axisXLeft = document.createElement("div");
    axisXLeft.setAttribute("id", "axis-x-left");
    view.ui.add(axisXLeft, "manual");

    const axisXRight = document.createElement("div");
    axisXRight.setAttribute("id", "axis-x-right");
    view.ui.add(axisXRight, "manual");

    _view.current = view;

    view.when(() => {
      _updateCrosshairColor.current();

      view.watch("center", () => _updateCrosshairColor.current());
      whenTrue(view, "stationary", () => {
        _performCrossHairTest.current();
      });
    });
  }, []);

  useEffect(() => {
    const reset = _selected.current.OBJECTID !== selected.OBJECTID;

    if (!reset) {
      return;
    }

    _selected.current = selected;
    _view.current.graphics.removeAll();
    _view.current.popup.close();

    const selectedPoint = new Point({
      x: _selected.current.x,
      y: _selected.current.y,
      spatialReference: { wkid: 4326 },
    });

    if (_selected.current.solved) {
      toggleViewFinder(false);

      _view.current
        .goTo(
          {
            center: selectedPoint,
            zoom: _selected.current.zoom_level,
          },
          {
            animate: true,
            duration: 1000,
            easing: "ease-out",
          }
        )
        .then(() => {
          _view.current.graphics.add(
            new Graphic({
              geometry: selectedPoint,
              symbol: {
                type: "picture-marker",
                url: pngMarker,
                width: "24px",
                height: "32px",
                yoffset: "16px",
              },
            })
          );

          _view.current.popup.dockEnabled = false;
          _view.current.popup.open({
            location: selectedPoint,
            title: _selected.current.location_name,
          });
        });
    } else {
      _view.current
        .goTo(
          {
            /*center: [_selected.current.x, _selected.current.y],*/
            zoom: _homeZoom.current,
          },
          {
            animate: true,
            duration: 1000,
            easing: "ease-in",
          }
        )
        .then(() => {
          toggleViewFinder(true);
          _updateCrosshairColor.current();
          _performCrossHairTest.current();
        });
    }
  }, [selected]);

  return <div id={id} className={className} style={{ cursor: "grab" }}></div>;
};

const boxToExtent = (view, square) => {
  const lowerLeft = view.toMap(
    new Point([square.offsetLeft, square.offsetTop + square.offsetHeight])
  );

  const upperRight = view.toMap(
    new Point([square.offsetLeft + square.offsetWidth, square.offsetTop])
  );

  return new Extent({
    xmin: lowerLeft.longitude,
    ymin: lowerLeft.latitude,
    xmax: upperRight.longitude,
    ymax: upperRight.latitude,
    spatialReference: {
      wkid: 4326,
    },
  });
};
