/* Copyright 2022 Esri
*
* Licensed under the Apache License Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

export const PhotoCredits = ({
    className, 
    style, 
    attribution, 
    sourceReferenceURL,
    license,
    licenseReferenceURL
    }) => {
    return(
        <div className={className} style={style}>
        Fotoğraf:
        {
        sourceReferenceURL && 
        <a href={sourceReferenceURL} 
            target="_blank" 
            rel="noreferrer" 
            className="ms-1 me-1">{attribution}</a>
        }
        {
        !sourceReferenceURL && 
        <span className="ms-1 me-1">{attribution}</span>
        }
        </div>
    );
}