/* Copyright 2022 Esri
 *
 * Licensed under the Apache License Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const Intro = ({
  className,
  style,
  title,
  description,
  instructions,
  hero,
  onDismiss: dismiss,
}) => {
  className = className + " d-flex justify-content-center align-items-center";
  return (
    <div id="intro" className={className} style={style}>
      <div
        id="inner"
        className="d-flex h-100 w-100 position-relative overflow-hidden  bg-white p-2"
      >
        <div
          className="flex-grow-1 flex-shrink-0 d-flex flex-column position-relative overflow-hidden p-2 m-0 m-sm-2 me-sm-1"
          style={{
            maxWidth: "600px",
            flexBasis: "80%",
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
        >
          <div className="overflow-auto">
            <h1 className="display-5 fw-bold">{title}</h1>
            <h2 className="mb-5" style={{ fontWeight: 300 }}>
              {description}
            </h2>
            <h5>Coğrafi bulmaca nasıl çalışır: </h5>
            <div
              id="instructions"
              dangerouslySetInnerHTML={{ __html: instructions }}
            ></div>
          </div>
          <button
            type="button"
            className="btn btn-primary align-self-center mt-4"
            onClick={() => dismiss()}
          >
            Başla
          </button>
        </div>
        <div
          className="flex-grow-1 d-none d-sm-block m-0 m-sm-2 ms-sm-1"
          style={{
            flexBasis: "40%",
            backgroundImage: `url(${hero})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover"
          }}
        ></div>
      </div>
    </div> /*intro*/
  );
};
