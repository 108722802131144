/* Copyright 2022 Esri
 *
 * Licensed under the Apache License Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import download from "downloadjs";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { useState } from "react";

export const CongratsScreen = ({
  className,
  style,
  title,
  hero,
  certificateURL,
  onDismiss: dismiss,
}) => {
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  const [name, setName] = useState(null);
  async function modifyPdf() {
    const url = "./certificate.pdf";
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const fontUrl = "https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf";
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const textSize = 40;
    const textWidth = ubuntuFont.widthOfTextAtSize(name, textSize);

    firstPage.drawText(name, {
      x: 420 - textWidth / 2,
      y: 300,
      size: 40,
      font: ubuntuFont,
      color: rgb(0.19, 0.06, 0.33),
    });

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, "sertifika.pdf", "application/pdf");
  }
  const tweetText = encodeURI(
    title +
      "coğrafi bulmacasını tamamladım! Sen de harita tabanlı bulmaca ile coğrafi bilgini test etmek ister misin?"
  );

  className = className + " d-flex justify-content-center align-items-center";
  return (
    <div id="congrats" className={className} style={style}>
      <div
        id="inner"
        className="h-auto position-relative overflow-hidden border bg-white ms-2 me-2 p-2"
        style={{ width: "95%", maxWidth: "1000px", maxHeight: "95%" }}
      >
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-close"
            onClick={() => {
              dismiss();
            }}
          ></button>
        </div>
        <div className="d-flex">
          <div
            className="flex-grow-1 flex-shrink-0 d-flex flex-column position-relative overflow-hidden p-2 m-0 m-sm-2 me-sm-1"
            style={{
              flexBasis: "50%",
              backgroundColor: "rgba(255,255,255,0.8)",
            }}
          >
            <div className="flex-grow-1 overflow-y-auto">
              <h1 className="display-5 fw-bold">Tebrikler!</h1>
              <p className="h5 mb-5" style={{ fontWeight: 400 }}>
                Türkiye Tarihi ve Kültürel Miraslar Coğrafi Bulmacasını
                başarıyla tamamladın!
              </p>
              <p className="h5" style={{ fontWeight: 400 }}>
                Bu başarıyı paylaşmak için:
              </p>
              <ul className="h5" style={{ fontWeight: 400 }}>
                <li className="mb-1">Adını yazıp, sertifikanı indir!</li>
                <li>
                  <p>Arkadaşlarınla sosyal medyada paylaş</p>
                  <div className="d-flex">
                    <a
                      href={
                        "https://twitter.com/intent/tweet?text=" +
                        tweetText +
                        "&url=" +
                        window.location.href
                      }
                      className="twitter-share-button btn btn-sm btn-link"
                      data-show-count="false"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Tweet
                    </a>
                    <div
                      className="fb-share-button"
                      data-href="https://developers.facebook.com/docs/plugins/"
                      data-layout="button"
                      data-size="small"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                        className="fb-xfbml-parse-ignore btn btn-sm btn-link"
                      >
                        Facebook
                      </a>
                      <a
                        href={
                          "https://www.linkedin.com/sharing/share-offsite/?url=" +
                          window.location.href
                        }
                        className="twitter-share-button btn btn-sm btn-link"
                        data-show-count="false"
                        target="_blank"
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </div>
                    <button
                      className="btn btn-sm btn-link"
                      title="Copy link"
                      onClick={(event) => {
                        copyToClipBoard();
                        alert("Copied!");
                      }}
                    >
                      Linki kopyala
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className=" m-0 m-sm-2 ms-sm-1 "
            style={{
              flexBasis: "50%",
              display: "flex!important",
              flexDirection: "column",
            }}
          >
            <input
              type="text"
              value={name}
              placeholder="İsminizi giriniz"
              style={{
                width: "100%",
              }}
              onChange={(e) => setName(e.target.value)}
            />
            <button
              onClick={() => {
                modifyPdf();
              }}
              style={{
                width: "100%",
                margin: "4px 0px",
              }}
              type="button"
              class="btn btn-outline-primary btn-sm"
            >
              İndir
            </button>
            <img
              src={hero}
              alt="sertifika"
              style={{
                width: "100%",
              }}
              onClick={() => {
                modifyPdf();
              }}
            />
          </div>
        </div>
      </div>
    </div> /*intro*/
  );
};
